<template>
  <div class="d-lg-flex half">
    <div class="bg order-1 d-none d-lg-inline-block"></div>
    <div class="contents order-2 order-md-1">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-7">
            <div class="logoNJ">
              <router-link to="/">
                <img
                  src="@/assets/img/logonj.png"
                  alt="NJ"
                  width="100"
                  class="mb-5"
                />
              </router-link>
              <h2 class="mb-5"><strong>PSB</strong> Nurul Jadid</h2>
            </div>
            <!-- <p class="mb-4">Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.</p> -->
            <form @submit.stop.prevent autocomplete="off">
              <div class="form-group first">
                <label for="username">No. Registrasi</label>
                <input
                  type="text"
                  v-model.trim="norek"
                  id="norek"
                  class="form-control mb-2"
                  placeholder="No. Registrasi"
                  aria-label="Norek"
                  autocomplete="off"
                  required
                  autofocus
                />
              </div>
              <label for="password">Password</label>
              <div class="input-group mb-4">
                <input
                  :type="inputpassword ? 'password' : 'text'"
                  v-model.trim="password"
                  id="password"
                  class="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  autocomplete="off"
                  required
                />
                <div class="input-group-append">
                  <span
                    role="button"
                    class="input-group-text"
                    style="width: 45px"
                    :aria-label="
                      inputpassword ? 'Show Password' : 'Hide Password'
                    "
                    @mousedown="showHidePass"
                    @mouseup="showHidePass"
                    @touchstart="showHidePass"
                    @touchend="showHidePass"
                    @contextmenu.stop.prevent
                  >
                    <!-- Dibolehkan tekan tahan lama mouse kemudian geser lepas untuk mempertahan posisi state -->
                    <fa-icon
                      :icon="
                        inputpassword ? ['fas', 'eye-slash'] : ['fas', 'eye']
                      "
                    ></fa-icon>
                  </span>
                </div>
              </div>

              <div class="d-flex mb-3 align-items-center">
                <span>
                  Belum memiliki akun?
                  <router-link to="/register">Buat baru</router-link>
                </span>
                <span class="ml-auto">
                  Atau
                  <router-link to="/forgot-password">Lupa Password</router-link>
                </span>
              </div>

              <button
                class="btn btn-login btn-block mb-3"
                type="submit"
                @click.stop.prevent="goLogin"
                :disabled="$store.state.loading"
              >
                <template v-if="$store.state.loading">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="ml-1">Loading...</span>
                </template>
                <span v-else>Login</span>
              </button>
              <div
                class="d-flex mb-3 align-items-center justify-content-center"
              >
                <span>
                  Kembali ke
                  <router-link to="/">Home</router-link>
                </span>
              </div>
              <div class="alert-wrapper">
                <Alert
                  :message="errorText"
                  variant="danger"
                  v-model="alertDismissCountDown"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert";
export default {
  title: "Login",
  components: { Alert },

  data() {
    return {
      norek: "",
      password: "",
      inputpassword: true,
      errorText: "",
      alertDismissCountDown: 0,
    };
  },

  methods: {
    goLogin() {
      /* make sure zero for clearly appear fade effect
      if do login many times with count down still running */
      this.alertDismissCountDown = 0;

      if (!this.norek || !this.password) {
        this.errorText = "Username & password harus diisi";
        this.alertDismissCountDown = 10;
        return;
      }

      this.$axios({
        method: "get",
        url: "/pendaftar/login",
        auth: {
          username: this.norek,
          password: this.password,
        },
        loading: true,
      })
        .then((res) => {
          this.$store.commit("setLoading", false);
          if (res.headers["x-ppdb-token"]) {
            this.$axios.defaults.headers["x-ppdb-token"] =
              res.headers["x-ppdb-token"];
            this.$store.commit("setToken", res.headers["x-ppdb-token"]);
            this.$psb.nes.instance.connect();
            this.$router.push({ name: "Profile" });
          }
        })
        .catch((err) => {
          this.$store.commit("setLoading", false);
          if (err.response) {
            if (err.response.status >= 400 && err.response.status < 500) {
              /* Error antara 400 - 499, biasanya kesalahan di user  */
              this.errorText = err.response.data.message || "Invalid Login";

              this.alertDismissCountDown = 10;
            }

            // ERROR SELAIN ANTARA 400 - 499, biarkan ditampilkan by notif modal
            // liat interceptor axios
          }
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },

    showHidePass() {
      this.inputpassword = !this.inputpassword;
    },
  },
};
</script>
<style scoped>
.bg {
  background-image: url("../assets/img/Musollah.jpg");
}
</style>
