<!--borrowed from @alfianwahid-->
<template>
  <transition name="fade">
    <div
      v-if="visible"
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      class="alert align-middle m-0 alert-dismissible"
      :class="`alert-${variant}`"
    >
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click.stop.prevent="dismiss"
      >
        ×
      </button>
      <div class="text-center" v-html="message"></div>
    </div>
  </transition>
</template>

<script>
const toInteger = (value, defaultValue = 0) => {
  const integer = parseInt(value, 10);
  return isNaN(integer) ? defaultValue : integer;
};

// Convert `show` value to a number
const parseShowToNumberCountDown = (show) => {
  if (show === "" || typeof show === "boolean") {
    return 0;
  }
  show = toInteger(show, 0);
  return show > 0 ? show : 0;
};

// Convert `show` value to a boolean
const parseShowToBooleanVisible = (show) => {
  if (show === "" || show === true) {
    return true;
  }

  if (toInteger(show, 0) < 1) {
    // Boolean will always return false for the above comparison
    return false;
  }

  return !!show;
};

export default {
  model: {
    prop: "show",
    event: "input",
  },

  props: {
    variant: {
      type: [String, Object],
      default: "info",
    },
    message: {
      type: [String, Object],
      default: null,
    },
    show: {
      type: [Boolean, Number, String],
      default: false,
    },
  },

  data() {
    return {
      visible: false,
      countDown: 0,
      countDownTimeout: null,
    };
  },

  watch: {
    show(newVal) {
      this.visible = parseShowToBooleanVisible(newVal);
      this.countDown = parseShowToNumberCountDown(newVal);
    },

    countDown(newVal) {
      this.clearCountDownInterval();

      if (this.show !== newVal) {
        // Update the v-model if needed
        this.$emit("input", newVal);
      }

      if (newVal > 0) {
        this.countDownTimeout = setTimeout(() => {
          this.countDown--;
        }, 1000);
      } else {
        this.$nextTick(() => {
          this.visible = false;
        });
      }
    },
  },

  methods: {
    dismiss() {
      this.clearCountDownInterval();
      this.visible = false;
      this.countDown = 0;
    },

    clearCountDownInterval() {
      if (this.countDownTimeout) {
        clearTimeout(this.countDownTimeout);
        this.countDownTimeout = null;
      }
    },
  },

  created() {
    this.visible = parseShowToBooleanVisible(this.show);
    this.countDown = parseShowToNumberCountDown(this.show);
  },

  beforeDestroy() {
    this.clearCountDownInterval();
  },
};
</script>
